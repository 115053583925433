import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import ChatModule from "../components/chat/ChatModule";
import { useMeasure } from "../hooks/useMeasure";
import { useAuth } from "../providers/AuthProvider";
import useMessages from "../hooks/useMessages";
import { getMsgPath } from "../utils";
import { MsgType } from "../types";


const timerSeconds = 10;
const totalImageReps = 3;

const AuditionCapture = ({ imgSrc, previewDefault = false, selfAuditionMode = false, webcamRef, audInfo, onCapture, audSessionId, hideChat = false, onPoseComplete = null }) => {
    const [preview, setPreview] = useState(previewDefault);
    const [videoHeight, setVideoHeight] = useState(0);
    const [containerRef, { width, height }] = useMeasure();
    const [stopFeed, setStopFeed] = useState(false);
    const [imgInfo, setImgInfo] = useState('OBSERVE POSE');

    // Timer
    const [timeLeft, setTimeLeft] = useState(timerSeconds);
    const [isActive, setIsActive] = useState(false);
    const [imgReps, setImgReps] = useState(1);

    const actions = useMessages(getMsgPath(audSessionId), MsgType.Action, true);

    const { user } = useAuth();

    const updateHeight = () => {
        setVideoHeight(containerRef?.current?.offsetHeight);
    }

    useEffect(() => {
        if (containerRef?.current) {
            setVideoHeight(containerRef?.current?.offsetHeight);
        }
    }, [containerRef]);

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    const handleCamFeed = (status) => {
        setStopFeed(status);
    }

    useEffect(() => {
        if (actions && actions?.message?.type === 'toggleCam') {
            setStopFeed(actions?.message?.action);
        }

        if (actions && actions?.message?.text === 'showPreview') {
            setPreview(true);
        }
        if (actions && actions?.message?.text === 'hidePreview') {
            setPreview(false);
        }
    }, [actions]);

    useEffect(() => {
        let intervalId;

        if (isActive && timeLeft > 0) {
            intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [isActive, timeLeft]);

    const startTimer = () => {
        setIsActive(true);
    };

    const stopTimer = () => {
        setIsActive(false);
        setTimeLeft(timerSeconds);
    };

    const resetTimer = () => {
        setTimeLeft(timerSeconds);
    };

    useEffect(() => {
        let msg;
        let timeout;

        if (timeLeft !== 0) {
            msg = `CAPTURING ${imgReps} OF ${totalImageReps} IN ${timeLeft}`
        }
        if (timeLeft === 0) {
            msg = "POSE CAPTURED";
            onCapture(imgReps);
            timeout = setTimeout(() => {
                if (imgReps < 3) {
                    setImgReps(imgReps + 1);
                    resetTimer();
                } else {
                    setImgInfo('OBSERVE POSE');
                    setImgReps(1);
                    stopTimer();
                }
            }, 3000);
        }
        if (timeLeft === timerSeconds && imgReps === 1) {
            msg = 'OBSERVE POSE';
        }
        setImgInfo(msg);

        return () => timeout && clearTimeout(timeout);
    }, [timeLeft])

    return (
        <>
            {(preview || selfAuditionMode) && (
                <div ref={containerRef} style={{ position: 'relative'}}>
                    <Webcam
                        forceScreenshotSourceSize
                        videoConstraints={{
                            height: 1080,
                            width: 1920
                        }}
                        style={{ width: 'w-full' }}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        itemRef=""
                    />
                    {stopFeed && (
                        <div className="h-full w-full bg-black flex items-center justify-center" style={{ position: 'absolute', top: 0, left: 0, zIndex: 11,}}>
                            <button className="text-white" onClick={() => handleCamFeed(false)}>RESUME CAM</button>
                        </div>
                    )}
                    <div className="p-1 text-right underline text-black w-full text-xs shadow-sm opacity-70" style={{ position: 'absolute', bottom: 0, zIndex: 10, }}>
                        <button className="underline text-red-700" onClick={() => handleCamFeed(true)}>STOP CAM</button>
                    </div>
                </div>
            )}
            {/* <div className="img-grid" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: 4 }}>
                <button type="button" onClick={() => onCapture(1)}>Capture 1</button>
                <button type="button" onClick={() => onCapture(2)}>Capture 2</button>
                <button type="button" onClick={() => onCapture(3)}>Capture 3</button>
            </div> */}
            {!selfAuditionMode && <ChatModule offset={height} senderId={user?.sessionId} sessionId={audSessionId} infoMsg={audInfo?.message} actions={actions} />}
            {selfAuditionMode && (
                <>
                    <div className="flex border-t-1 text-green-900 bg-green-50 p-2 text-center justify-center uppercase border-slate-500 divide-gray-800 row">
                        {imgInfo}
                    </div>
                    <div className="p-1 flex-grow">
                        <p className="block mt-1 font-sans text-xs antialiased font-normal leading-relaxed text-gray-600">
                            Observe the pose carefully and click Ready to auto-capture the pose. System will capture {totalImageReps} poses every {timerSeconds} seconds.
                        </p>
                    </div>
                    <div className="img-grid" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: 4 }}>
                        <div className="img-capture-wrap">
                            {imgSrc && imgSrc?.[1] && <img width='100%' height={40} src={imgSrc?.[1]} />}
                        </div>
                        <div className="img-capture-wrap">
                            {imgSrc && imgSrc?.[2] && <img width='100%' height={40} src={imgSrc?.[2]} />}
                        </div>
                        <div className="img-capture-wrap">
                            {imgSrc && imgSrc?.[3] && <img width='100%' height={40} src={imgSrc?.[3]} />}
                        </div>
                    </div>
                    <div className="flex border-t-1 border-slate-500 row">
                        <button
                            className="block rounded-b-sm text-blue-700 border-t-slate-100 border-t w-full p-2 bg-white hover:bg-slate-100"
                            type="button"
                            onClick={startTimer}
                        >
                            <span className="fal fa-camera">&nbsp;&nbsp;</span> TAKE / RE-TAKE
                        </button>
                        <button
                            className="block rounded-b-sm text-blue-700 border-t-slate-100 border-t w-full p-2 bg-white hover:bg-slate-100"
                            type="button"
                            onClick={() => onPoseComplete?.()}
                        >
                            <span className="fal fa-check">&nbsp;&nbsp;</span> MARK CAPTURED
                        </button>
                    </div>
                </>
            )}
        </>
    )
}

export default AuditionCapture;
