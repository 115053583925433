import React from 'react';

import './App.css';
import { Routes, Route } from "react-router-dom";
import Layout from './components/Layout';
import RedirectToBase from './components/RedirectToBase';
import Application from './components/Application';
import JobApplication from './components/JobApplication';
import AdminPortal from './components/AdminPortal';
import AdminLayout from './components/AdminLayout';
import AdminLogin from './pages/IMMLogin';

import toast, { Toaster } from 'react-hot-toast';
import JobPortal from './components/JobPortal';
import AuditionHome from './pages/AuditionHome';
import AuditionConsent from './pages/AuditionConsent';
import AuditionInterview from './pages/AuditionInterview';
import AdminHome from './pages/AdminHome';
import VirtualAuditionInterview from './pages/VirtualAuditionInterview';
import PrivateLayout from './components/PrivateLayout';

function App() {
    return (
        <div className="App">
            <Toaster toastOptions={{ duration: 3000, style: { fontSize: 14, fontWeight: 500 }, position: 'top-center' }} />
            <Routes>
                <Route path='/' element={<RedirectToBase />} />
                
                
                

                <Route path="/remote/applications" element={<Layout />}>
                    <Route index element={<Application />} />
                </Route>
                <Route path="/jobs/apply" element={<Layout />}>
                    <Route index element={<JobApplication />} />
                </Route>


                <Route path="/audition/login" element={<Layout />}>
                    <Route index element={<AdminLogin />} />
                </Route>
                <Route path="/audition/consent" element={<PrivateLayout />}>
                    <Route index element={<AuditionConsent />} />
                </Route>
                <Route path="/audition/home" element={<PrivateLayout />}>
                    <Route index element={<AuditionHome />} />
                </Route>
                <Route path="/audition/interview" element={<PrivateLayout />}>
                    <Route index element={<AuditionInterview />} />
                </Route>
                <Route path="/virtual/audition/interview" element={<PrivateLayout />}>
                    <Route index element={<VirtualAuditionInterview />} />
                </Route>


                <Route path="/admin/:audSessionId" element={<PrivateLayout />}>
                    <Route index element={<AdminHome />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
