
import CryptoJS from "crypto-js";

export function encryptWithSHA512(data) {
    const hashed = CryptoJS.HmacSHA512(data, process.env.REACT_APP_SECRET_KEY);
    const encryptedHex = hashed.toString(CryptoJS.enc.Hex);
    return encryptedHex;
}

export function encryptWithSHA256(data) {
    const hashed = CryptoJS.HmacSHA512(data, process.env.REACT_APP_SECRET_KEY);
    const encryptedHex = hashed.toString(CryptoJS.enc.Hex);
    return encryptedHex;
}

export function encryptHash(data) {
    const hashed = CryptoJS.HmacSHA512(data, process.env.REACT_APP_SECRET_KEY);
    const encryptedHex = hashed.toString(CryptoJS.enc.Hex);
    return encryptedHex;
}

export const getMsgPath = (sessionId) => `messages/audition/${sessionId}`;