import React from "react";
import { useAuth } from "../providers/AuthProvider";

const PrivateHeader = ({ minimal = false, onsiteMode = false }) => {
const { user, isAuthenticated, logout } = useAuth();
    const { audition } = user || {};
    const { round, adminName, audLocation, adminDesignation = 'Senior Photographer', meetingLink = '#' } = audition || {};

    return (
        <>
            {minimal ? (
                <>
                    <h3 className={`page-title ${onsiteMode ? 'no-margin' : ''}`} style={{ marginBottom: onsiteMode ? 0 : 16 }}>
                        WELCOME, {user?.profileName?.toUpperCase()}
                        &nbsp;
                        <span className="btn-logout" onClick={logout}>LOGOUT</span>
                    </h3>
                </>
            ): (
                <div className="flex bg-slate-50 items-center page-title border border-solid border-2 border-slate-100 rounded-md rounded-b-sm p-2" style={{ marginBottom: 8 }}>
                    <div className="flex flex-col">
                        <span className="text-sm tracking-normal text-left text-black">WELCOME, <span className="font-bold">{user?.profileName?.toUpperCase()}</span></span>
                        <span className="font-sans tracking-normal font-medium text-xs text-left text-slate-500 pt-1">{audLocation} Audition - Round {round} | {adminName} - {adminDesignation} - IMM</span>
                    </div>
                    &nbsp;
                    <div className="grow">&nbsp;</div>
                    <a href={meetingLink} target="_blank" className="btn-logout pr-4">MEETING LINK</a>
                    <span className="btn-logout" onClick={logout}>LOGOUT</span>
                </div>
            )}
        </>
    )
}

export default PrivateHeader;
