import React, { useMemo } from "react";

import Chip from '@mui/material/Chip';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';

const AuditionOverview = ({ auditionMap, activeStep, isLastSlide, onAuditionComplete, orientation = 'vertical', hideTitle = false, onPoseComplete = null }) => {
    
    const steps = useMemo(() => {
        return Object.keys(auditionMap)?.map(audition => {
            return {
                label: auditionMap[audition].title,
                description: '',
            }
        })
    }, [auditionMap]);

    return (
        <>
            <div className="flex flex-column flex-grow">            
                {!hideTitle && <h4>Audition Overview</h4>}
                <Stepper activeStep={activeStep} orientation={orientation}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <p>{step.description}</p>
                                {index === activeStep && (
                                    <Button
                                        variant="contained"
                                        disabled={!isLastSlide}
                                        sx={{ mt: 1, mr: 1 }}
                                        onClick={onAuditionComplete}
                                    >
                                        Complete
                                    </Button>
                                )}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div className="flex border-t-1 border-slate-500 row">
                <button
                    className="block rounded-b-sm text-blue-700 border-t-slate-100 border-t w-full p-2 bg-white hover:bg-slate-100"
                    type="button"
                    onClick={() => onPoseComplete?.()}
                >
                    <span className="fal fa-check">&nbsp;&nbsp;</span> MARK CAPTURED
                </button>
            </div>
        </>
    )
}

export default AuditionOverview;
