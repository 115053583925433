import React, { useEffect, useState } from "react"; 

import { useForm } from "react-hook-form";
import PrivateHeader from "../components/PrivateHeader";
import { useAuth } from "../providers/AuthProvider";
import { Navigate } from "react-router-dom";
import AuditionCapture from "./AuditionCapture";
import useMessages from "../hooks/useMessages";
import { getMsgPath } from "../utils";
import { MsgType } from "../types";
import useImageOrientation from "../hooks/useImageOrientation";

export const AUDITION_KEY = 'imm_aud_key';

const VirtualAuditionInterview = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();
    let api;

    // WebCam
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    const [showSlideshow, setShowSlideshow] = useState(false);
    const { audSessionId, logout } = useAuth();
    // const [interviewKey, setInterviewKey] = useState(localStorage.getItem(AUDITION_KEY || 0));
    const interviewKey = localStorage.getItem(AUDITION_KEY) || 0;
    const [isLastSlide, setIsLastSlide] = useState(false);
    
    const slideShowData = useMessages(getMsgPath(audSessionId), MsgType.SlideShow, true);
    const audInfoData = useMessages(getMsgPath(audSessionId), MsgType.Info, true);

    const orientation = useImageOrientation();
    const imgOrntClss = orientation === 'portrait' ? 'h-full' : 'h-4/5';

    const { consent, reviewed } = useAuth();

    useEffect(() => {
        if (audInfoData) {
            const { message } = audInfoData || {};
            if (message.type === 'action' && message.text === 'logout') {
                logout();
            }
        }
    }, [audInfoData]);

    const onSubmit = (data) => {
        console.log(data);
    }

    const closeImageViewer = () => {

    }

    const onSlideChange = (section, origin, destination, direction, trigger) => {
        const { isLast } = destination || {};
        setIsLastSlide(isLast);
        setImgSrc(null);
    }

    const handleAuditionComplete = () => {
        if (interviewKey < 5) {
            localStorage.setItem(AUDITION_KEY, +interviewKey + 1);
            window.location.reload();
        }
    }

    const handleOnCapture = (wcIndex) => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc({
            ...imgSrc,
            [wcIndex]: imageSrc,
        });
    }

    if (!consent) {
        return <Navigate to='/audition/consent' />
    }

    if (!reviewed) {
        return <Navigate to='/audition/home' />
    }

    const sendMsg = () => {
        // sendMessage(MESSAGE_PATH, 'Acknowledged!')
    }

    const imgBase = 'https://imm-audition.s3.ap-south-1.amazonaws.com/assets/aud_banner.png';

    return (
        <>
            
            <div className="audition-interview-onsite audition-virtual">
                <PrivateHeader />
                <div className="audition-control-wrap">
                    <div className="flex flex-column flex-1 row-gap-12">
                        
                        <div className="audition-main-wrap">
                            <div className="aud-section relative panel-1 flex-8">
                                <div className="img-section">
                                    <div className="img-wrapper flex align-center justify-center">
                                        <img id="aud-image" className={`object-contain ${imgOrntClss} aspect-auto`} src={slideShowData?.message} />
                                        {!slideShowData?.message && (
                                            <img id="aud-banner" className={`object-contain h-4/5 aspect-auto`} src={imgBase} />
                                        )}
                                    </div>
                                    <div className="wm-wrap">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                            <div className="aud-section panel-2 flex-4 flex-column p-4">
                                <AuditionCapture
                                    imgSrc={imgSrc}
                                    audInfo={audInfoData}
                                    onCapture={handleOnCapture}
                                    webcamRef={webcamRef}
                                    audSessionId={audSessionId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VirtualAuditionInterview;
