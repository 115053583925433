import React, { useEffect, useMemo, useRef } from "react";
import ChatMessage from "./ChatMessage";
import { useForm } from "react-hook-form";
import { useFirebase } from "../../providers/FirebaseProvider";
import useMessages from "../../hooks/useMessages";
import { useAuth } from "../../providers/AuthProvider";

const ChatModule = ({ offset, sessionId, infoMsg, senderId, adminMode = false, height = null, actions }) => {
    const scrollRef = useRef(null);
    const { sendAuditionMessage } = useFirebase();
    const msgPath = `messages/audition/${sessionId}`;
    const chatStream = useMessages(msgPath);

    const { text = 'INITIATING AUDITION', color = 'violet'} = infoMsg || {};
    const { message: { type, text: actionText, value: actionVal } = {} } = actions || {};

    const fontValue = type === 'changeFont' && actionVal;

    const { user } = useAuth();

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();

    const onSubmit = (data, e) => {
        const { usermsg } = data || {};
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
        if (usermsg) {
            e.target.reset();
            sendAuditionMessage(usermsg, sessionId, senderId, user?.profileName);
        }
    }

    useEffect(() => {
        if (chatStream) {
            scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatStream])

    return (
        <form id="form-chat-module" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col w-full overflow-hidden bg-slate-200" style={{ height: height ?? `calc(100vh - ${offset + 160}px)`}}>
                    <div className="flex flex-col w-full overflow-hidden h-full">
                        {!adminMode && (
                            <div className={`bg-${color}-500 w-full p-1`}>
                                <p className={`text-white text-center`} style={{ fontSize: `${fontValue ?? 1}rem`}}>{text}</p>
                            </div>
                        )}
                        <div className="flex flex-col mr-4 mt-5 pl-4 grow overflow-y-auto">
                            {chatStream?.map(msgObj => <ChatMessage key={msgObj?.time} time={msgObj?.time} msg={msgObj?.message} name={msgObj?.senderName} isReply={msgObj?.senderId === senderId} />)}
                            <div ref={scrollRef} />
                        </div>
                        <div id="chat-inp-wrap" className="pb-3 pr-4 pl-4">
                            <input className="w-full text-slate-500 font-normal bg-white py-3 px-3 shadow-sm rounded-md text-sm" type="text" placeholder="type your message here..." {...register("usermsg", { required: true })}  />
                        </div>
                    </div>
            </div>
        </form>
    )
}

export default ChatModule;
