import React from "react";
import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import PrivateHeader from "../components/PrivateHeader";
import { Navigate, useNavigate } from "react-router-dom";
import { AUTH_SESSION_AUDITION_CONSENT } from "../constants";
import { useAuth } from "../providers/AuthProvider";

const AuditionConsent = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();

    const navigate = useNavigate();
    const { consent, user } = useAuth();
    console.log({ user })

    const onSubmit = () => {
        localStorage.setItem(AUTH_SESSION_AUDITION_CONSENT, true);
        navigate('/audition/home');
    }

    if (consent) {
        const to = user?.onsite === true ? '/audition/home' : '/virtual/audition/interview'
        return <Navigate to={to} />
    }

    const auditionType = user?.onsite === true ? 'Onsite' : 'Virtual'

    return (
        <div style={{ paddingTop: 32, display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 32px)' }}>
            <div className="page-wrap" style={{ flexGrow: 1 }}>
                <PrivateHeader minimal />
                
                <div className="consent-form">
                    <h3 style={{ fontWeight: 'bold'}}>Consent Form</h3>
                    <div className="consent-data">
                        <div className="consent-list">
                            <p style={{ marginBottom: 12 }}>
                            Thank you for your interest in the modeling audition with IMM Models. We appreciate your enthusiasm. Before proceeding with the audition, please take note of the following important details and provide your consent
                            </p>

                            <div style={{ height: 360, overflowY: 'scroll'}}>
                                <ul className="consent-items">
                                    <li>
                                        <h4>Purpose of the Audition:</h4>
                                        <span>
                                            The purpose of the {auditionType} Modelling Audition Interview is to assess your modeling skills, presence, and suitability for potential projects with our agency or affiliated clients.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Voluntary Participation:</h4>
                                        <span>
                                            Your participation in the audition is entirely voluntary, and you have the right to withdraw at any stage without providing a reason.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Photography and Videography:</h4>
                                        <span>
                                            The audition may involve the capture of photographs and videos for assessment purposes. These images and videos may be used internally for evaluation and, with your explicit permission, in promotional materials related to our agency.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Confidentiality:</h4>
                                        <span>
                                            Any personal information you provide or that is gathered during the audition process will be treated confidentially. It will only be shared with relevant personnel involved in the audition process.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Strict Privacy on Audition Materials:</h4>
                                        <span>
                                            Audition materials (photographs and videos) will not be used for promotional and marketing purposes, including but not limited to our website, social media, and other advertising platforms without your explicit content.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Duration and Schedule:</h4>
                                        <span>
                                        The audition may take place over a specified duration, and the schedule will be communicated to you in advance. Please ensure your availability during the scheduled time.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Dress Code for Audition:</h4>
                                        <span>
                                        Nudity is not involved in photoshoots, but I may be requested to go topless/wear a bikini during the audition. Full-wear outfits and jeans should be avoided; instead, short light-colored attire such as skaters is recommended.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Feedback:</h4>
                                        <span>
                                        You will receive feedback on your performance during the audition. Constructive criticism will be provided to help you understand areas for improvement.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Code of Conduct:</h4>
                                        <span>
                                        We expect all participants to adhere to a professional code of conduct during the audition. Any inappropriate behavior may result in immediate disqualification.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Right to Refuse:</h4>
                                        <span>
                                        We reserve the right to refuse participation or discontinue the audition process if we believe it is in the best interest of all parties involved.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="submissions-validate" style={{ paddingTop: 32 }}>
                            <button onClick={onSubmit} id="submissions-submit" type="submit" className="submit">I CONSENT</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AuditionConsent;
