import { AUTH_SESSION_RECORD_ID } from "../constants";
import { API_BASE } from "../providers/AuthProvider";

const { useState, useEffect } = require("react")

const useAuthorize = () => {
    const [isAuthorized, setAuthorized] = useState(false);
    const [userRecord, setUserRecord] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const onAuthorize = ({ status, msg, data }) => {
        if (status === 200) {
            setAuthorized(true);
            setUserRecord(data);
        }
    }

    useEffect(() => {
        const sessionId = localStorage.getItem(AUTH_SESSION_RECORD_ID);
        if (!sessionId) {
            setLoading(false);
            return;
        }
        fetch(`${API_BASE}/api/authorize`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ sessionRecordId: sessionId  }) })
            .then(res => res.json())
            .then(onAuthorize)
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, []);
    
    return {
        isAuthorized,
        userRecord,
        isAuthorizeLoading: isLoading,
    }
}

export default useAuthorize;
