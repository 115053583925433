import React from 'react';
import ReactDOM from 'react-dom/client';

import Modal from 'react-modal';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from 'firebase/app';
// import { initializeApp } from "firebase/analytics";
// import { getAnalytics } from "firebase/analytics";
import { BrowserRouter } from "react-router-dom";
import FirebaseProvider from './providers/FirebaseProvider';
import AuthProvider from './providers/AuthProvider';
import toast, { Toaster } from 'react-hot-toast';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyB_410Dd6JzVetTGhvMwtXM5FT2gOVlYaY",
//     authDomain: "fashiondestination-af6e0.firebaseapp.com",
//     projectId: "fashiondestination-af6e0",
//     storageBucket: "fashiondestination-af6e0.appspot.com",
//     messagingSenderId: "7583090243",
//     appId: "1:7583090243:web:c7f1ca57a212029235f6f6",
//     measurementId: "G-LFM0HRQPX9"
// };

// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);
// const analytics = firebase.getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
Modal.setAppElement('#root');

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <FirebaseProvider>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </FirebaseProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
