import React, { useEffect } from "react";

const RedirectToBase = () => {
    useEffect(() => {
        window.location.replace('https://www.immmodels.com');
    }, [])

    return (
        <>
        </>
    )
}

export default RedirectToBase;
