import AuditionData from './mock/auditionImages.json';

export const Collection = {
    'Registrations': 'registrations',
    'Jobs': 'jobs'
}

export const UserStatus = {
    'NEW': 0,
    'SHORTLISTED': 1,
    'SKYPE_RECEIVED': 2,
    'PROFILE_ID_SENT': 3,
    'PROFILE_ACTIVATED': 4,
    'AUDITION_INVITE': 5,
    'ROUND_1_SELECT': 6,
    'MODEL_PORTFOLIO_REQ': 7,
    'ID_VERIFICATION_REQ_SENT': 8,
    'MODEL_INVITE_SENT': 10,
}

export const MailTypes = {
    'ModelShortlisted': 1,
    'ModelSkypeRecieved': 2,
    'ProfileId': 3,
    'AuditionInvite': 5,
    'AuditionRound1Select': 6,
    'FashionPortfolioRequest': 7,
    'IDVerificationRequest': 8,
    'IDActivationRequest': 9,
    'FashionModelInvite': 10,
}

const JOB_LIST = {
    imm5210: {
        id: 2,
        shortName: 'DE',
        name: 'Data Entry / Content Writing and Copywriting (Part-time - Work From Home)',
        code: 'IMM5210',
    },
    imm5776: {
        id: 3,
        shortName: 'DM',
        name: 'Digital Marketing (Part-time - Work From Home)',
        code: 'IMM5776',
    },
    imm5216: {
        id: 4,
        shortName: 'VA',
        name: 'Virtual Assistance (Part-time - Work From Home)',
        code: 'IMM5216',
    },
    immt0012: {
        id: 5,
        shortName: 'OT',
        name: 'Online Teaching and Tutoring (Part-time - Work From Home)',
        code: 'IMMT0012',
    },
    imm5331: {
        id: 6,
        shortName: 'VHRC',
        name: 'Virtual Human Resource Consultant (Part-time - Work From Home)',
        code: 'IMM5331',
    },
    imm5025: {
        id: 7,
        shortName: 'FEBE',
        name: 'Front-end / Back-end Engineer (Part-time - Work From Home)',
        code: 'IMM5025',
    },
    imm5021: {
        id: 8,
        shortName: 'QA',
        name: 'Virtual QA Engineer (Manual/Automation) (Part-time - Work From Home)',
        code: 'IMM5021',
    },
    imm4222: {
        id: 9,
        shortName: 'VSE',
        name: 'Virtual Support Engineer (Part-time - Work From Home)',
        code: 'IMM4222',
    },
    imm5213: {
        id: 10,
        shortName: 'GO',
        name: 'Senior Go Developer (Full-time - Work From Home)',
        code: 'IMM5213',
    },
    imm4773: {
        id: 11,
        shortName: 'LDA',
        name: 'Lead Digital Artist (Full-time - Work From Home)',
        code: 'IMM4773',
    },
    imm4214: {
        id: 12,
        shortName: 'CW',
        name: 'Content Writer (Part Time & Full-time - Work From Home)',
        code: 'IMM4214',
    },
    imm4240: {
        id: 13,
        shortName: 'FD',
        name: 'Fashion Designer (Part Time & Full-time - Work From Home)',
        code: 'IMM4240',
    },
};

export const getJobId = (jobRefId) => {
    const defaultJobRole = { id: 1, name: 'Virtual Fashion Model', shortName: 'FM' }
    if (!jobRefId) return defaultJobRole;
    return JOB_LIST?.[jobRefId] ?? defaultJobRole;
}

export const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}


export const getAuditionDetails = (profileId, cplPref = 'm2f') => {
    const AuditionImages = profileId in AuditionData ? AuditionData?.[profileId] : AuditionData?.['777777'];
    return {
        auditionIndex: Object.keys(AuditionImages).reduce((acc, cur, idx) => {
            return {
                ...acc,
                [idx]: cur,
            }
        }, {}),
        auditionMap: {
            'solo_c1': {
                progess: 1,
                steps: 6,
                title: 'Individual Model - Casual Prop (Variant 1)',
                images: AuditionImages?.solo_c1,
            },
            'solo_c2': {
                progess: 2,
                steps: 6,
                title: 'Individual Model - Casual Prop (Variant 2)',
                images: AuditionImages?.solo_c2,
            },
            'solo_b1': {
                progess: 3,
                steps: 6,
                title: 'Individual Model - Bikini Prop (Variant 1)',
                images: AuditionImages?.solo_b1,
            },
            'solo_b2': {
                progess: 4,
                steps: 6,
                title: 'Individual Model - Bikini Prop (Variant 2)',
                images: AuditionImages?.solo_b2,
            },
            'solo_t1': {
                progess: 5,
                steps: 6,
                title: 'Individual Model - Topless',
                images: AuditionImages?.solo_t1,
            },
            'couple_s1': {
                progess: 6,
                steps: 6,
                title: 'Couple Round - Dynamic',
                images: AuditionImages?.couple_s1?.[cplPref] ?? AuditionImages?.couple_s1?.['m2f'],
            }
        },
    }
}

export const AUTH_SESSION_KEY = 'SESS_IMM_AUTH_INFO';
export const AUTH_SESSION_PROFILE_ID = 'SESS_IMM_PROFILE_ID';
export const AUTH_SESSION_AUDITION_CONSENT = 'SESS_IMM_AUDITION_CONSENT';
export const AUTH_SESSION_AUDITION_REVIEW = 'SESS_IMM_AUDITION_REVIEW';
export const AUTH_SESSION_RECORD_ID = 'SESS_IMM_AUTH_SESSION_ID';
export const AUTH_AUD_SESSION_RECORD_ID = 'SESS_IMM_AUDITION_SESSION_ID';
export const AUTH_AUD_SESSION_TYPE = 'SESS_IMM_AUDITION_SESSION_TYPE';
