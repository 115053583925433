import React from "react";
import { formatDistance } from 'date-fns';

const ChatMessage = ({ msg, name, time, isReply = false }) => {
    const timeAgo =  `${formatDistance(new Date(time), new Date(), { addSuffix: true })}`;
    return (
        <>
            {isReply ? (
                <div className="flex flex-col justify-start mb-4">
                    {/* <p className="ml-2 pb-1 text-xs text-right font-normal">{name}</p> */}
                    <div className="ml-2 py-3 px-4 bg-white rounded-xl text-slate-900 text-xs font-normal">
                        {msg}
                    </div>
                </div>
            ) : (
                <div className="flex flex-col justify-end mb-4">
                    <span className="mr-2 pb-1 text-xs text-left font-normal">{name} <span className="text-xxs text-right font-normal text-slate-500">{timeAgo}</span></span>
                    <div className="mr-2 py-3 px-4 bg-blue-950 rounded-xl text-white text-xs font-normal">
                        {msg}
                    </div>
                </div>
            )}
        </>
    )
}

export default ChatMessage;
