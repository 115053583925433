import React from "react";
import burgerIcon from '../icons/burger.svg';
import logo from '../icons/logo.svg';

const Header = () => {
    return (
        <>
            <div id="menu">
                <div className="menu-desktop">
                    <div className="menu-desktop-logo">
                        <a href="https://www.immmodels.com/london/">
                            <img src={logo} style={{ width: 170 }} alt="IMM LONDON LOGO" title="IMM LONDON LOGO" />
                        </a>
                    </div>
                    <ul className="menu-desktop-navigation">
                        <li className="menu-desktop-navigation-main-element">
                            <span><span className="menu-desktop-navigation-list-element">men</span> <i className="fal fa-chevron-down"></i></span>
                            <ul>
                                <li><a href="https://www.immmodels.com/london/male-models/development/">development</a></li>
                                <li><a href="https://www.immmodels.com/london/male-models/main-board/">main board</a></li>
                                <li><a href="https://www.immmodels.com/london/male-models/classics/">classics</a></li>
                                <li><a href="https://www.immmodels.com/london/male-models/curve/">curve</a></li>
                            </ul>
                        </li>
                        <li className="menu-desktop-navigation-main-element">
                            <span><span className="menu-desktop-navigation-list-element">women</span> <i className="fal fa-chevron-down"></i></span>
                            <ul>
                                <li><a href="https://www.immmodels.com/london/female-models/development/">development</a></li>
                                <li><a href="https://www.immmodels.com/london/female-models/main-board/">main board</a></li>
                                <li><a href="https://www.immmodels.com/london/female-models/commercial/">commercial</a></li>
                                <li><a href="https://www.immmodels.com/london/female-models/classics/">classics</a></li>
                            </ul>
                        </li>
                        <li className="menu-desktop-navigation-main-element">
                            <span><span className="menu-desktop-navigation-list-element">curve</span> <i className="fal fa-chevron-down"></i></span>
                            <ul>
                                <li><a href="https://www.immmodels.com/london/plus-size/development/">development</a></li>
                                <li><a href="https://www.immmodels.com/london/plus-size/main-board/">main board</a></li>
                            </ul>
                        </li>
                        <li className="menu-desktop-navigation-main-element">
                            <span className="menu-desktop-navigation-list-element">
                                <a href="https://www.immmodels.com/london/non-binary/">non binary</a>
                            </span>
                        </li>
                        <li className="menu-desktop-navigation-main-element">
                            <span className="menu-desktop-navigation-list-element">
                                <a href="https://www.immmodels.com/london/influencers/">influencers</a>
                            </span>
                        </li>
                        <li className="menu-desktop-navigation-main-element hide-on-tablet">
                            <span className="menu-desktop-navigation-list-element">
                                <a href="https://www.immmodels.com/london/">blog</a>
                            </span>
                        </li>
                        <li className="menu-desktop-navigation-main-element hide-on-tablet">
                            <span className="menu-desktop-navigation-list-element">
                                <a href="https://www.instagram.com/immmodels/" target="_blank">instagram</a>
                            </span>
                        </li>
                        <li className="menu-desktop-navigation-main-element hide-on-tablet">
                            <span><span className="menu-desktop-navigation-list-element">info</span> <i className="fal fa-chevron-down"></i></span>
                            <ul>
                                <li><a href="https://www.immmodels.com/london/contact/">contact</a></li>
                                <li><a href="https://www.immmodels.com/london/about/">about us</a></li>
                                <li><a href="https://www.immmodels.com/london/applications/">applications</a></li>
                                <li><a href="https://www.immmodels.com/london/terms-and-conditions/">terms and<br />conditions</a></li>
                                <li><a href="https://www.immmodels.com/london/careers/">careers</a></li>
                            </ul>
                        </li>
                        {/* <li className="menu-desktop-navigation-main-element search-desktop-input">
                            <span className="menu-desktop-navigation-list-element">
                                <label htmlFor="search-models"><i className="fal fa-search"></i></label><input type="text" id="search-models" placeholder="search" />
                            </span>
                        </li> */}
                    </ul>
                    <div className="menu-mobile-icons">
                        <i className="open-mobile-search fal fa-search"></i>
                        <i className="close-mobile-search fal fa-times"></i>
                        <a href="mailto:bookings@immagencygroup.com" target="_blank"><i className="fal fa-envelope"></i></a>
                        <a href="tel:+442076109111" target="_blank"><i className="fas fa-phone"></i></a>
                    </div>
                    <div className="menu-boards-navigation hide-on-tablet">
                        <a className="board-active" href="https://www.immmodels.com/london/">London</a>
                        /
                        <a className="" href="https://www.immmodels.com/manchester/">Manchester</a>
                    </div>
                    <div className="tablet-burger-menu">
                        <img src={burgerIcon} alt="open menu" title="open menu" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;