import React, { useEffect, useState } from "react";
import ReactFullpage from '@fullpage/react-fullpage';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ImageViewer from "react-simple-image-viewer";

import { getStorage, ref, uploadBytes } from 'firebase/storage';

import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import AuditionOverview from "../components/AuditionOverview";
import { getAuditionDetails } from "../constants";
import PrivateHeader from "../components/PrivateHeader";
import { useAuth } from "../providers/AuthProvider";
import { Navigate } from "react-router-dom";
import AuditionInfo from "../components/AuditionInfo";
import AuditionCapture from "./AuditionCapture";
import { useFirebase } from "../providers/FirebaseProvider";
import useMessages from "../hooks/useMessages";

export const AUDITION_KEY = 'imm_aud_key';
export const AUDITION_CPL_PARTNER = 'm2f';
const MESSAGE_PATH = 'messages/fash_aud_chat_mode';

const onsiteMode = true;

const AuditionInterview = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();
    let api;

    // WebCam
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    const [auditionMode, setAuditionMode] = useState('assisted');
    const [couplePartner, setCouplePartner] = useState(localStorage.getItem(AUDITION_CPL_PARTNER) || 'm2f');
    const [showSlideshow, setShowSlideshow] = useState(false);
    const { uploadFile } = useFirebase();
    // const [interviewKey, setInterviewKey] = useState(localStorage.getItem(AUDITION_KEY || 0));
    const interviewKey = localStorage.getItem(AUDITION_KEY) || 0;
    const [isLastSlide, setIsLastSlide] = useState(false);

    // const { listenToMessages, sendMessage } = useFirebase();
    // const messages = useMessages(MESSAGE_PATH);
    // console.log({messages})


    // const onMessageReceived = (msg) => {
    //     console.log('Msg from server', msg);
    // }

    // useEffect(() => {
    //     listenToMessages(MESSAGE_PATH, onMessageReceived)
    // }, [listenToMessages])


    const { consent, reviewed, logout, user } = useAuth();
    const { sessionId: profileId } = user || {};

    const { auditionIndex, auditionMap } = getAuditionDetails(profileId, couplePartner);
    console.log({auditionIndex, auditionMap })
    
    const sessionName = auditionIndex?.[interviewKey];
    const nowShowing = auditionMap?.[sessionName];
    const selfAuditionMode = true;


    const onSubmit = (data) => {
        console.log(data);
    }

    const closeImageViewer = () => {

    }

    const onSlideChange = (section, origin, destination, direction, trigger) => {
        const { isLast } = destination || {};
        setIsLastSlide(isLast);
        setImgSrc(null);
    }

    const handleAuditionComplete = () => {
        const iKey = +interviewKey + 1;
        if (iKey < 6) {
            localStorage.setItem(AUDITION_KEY, +interviewKey + 1);
            window.location.reload();
        }
        if (iKey === 6) {
            logout();
        }
    }

    const handleOnCapture = async (wcIndex) => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc({
            ...imgSrc,
            [wcIndex]: imageSrc,
        });
        const snapshot = await uploadFile(imageSrc, `img_snap_${Date.now()}.jpg`);
    }


    const handleAuditionMode = (_, mode) => {
        setAuditionMode(mode);
    }
    
    const handleCouplePartner = (_, mode) => {
        localStorage.setItem(AUDITION_CPL_PARTNER, mode);
        window.location.reload();
    }

    const handleMarkComplete = () => {
        console.log('mark completed');
        api.moveSlideRight();
    }


    if (!consent) {
        return <Navigate to='/audition/consent' />
    }

    if (!reviewed) {
        return <Navigate to='/audition/home' />
    }

    // const sendMsg = () => {
    //     sendMessage(MESSAGE_PATH, 'Acknowledged!')
    // }

    return (
        <>
            <div className="audition-interview-onsite">
                <PrivateHeader minimal onsiteMode />
                <div className="audition-control-wrap">
                    <div className="flex flex-column flex-1 row-gap-12">
                        <div className="info-wrap flex" style={{ alignItems: 'center'}}>
                            <div className="flex-grow">
                                <h1 className="page-info" style={{ marginTop: 12, paddingBottom: 8 }}>{nowShowing?.title}</h1>
                                <span className="page-caption">Progress: 0{nowShowing?.progess} of 0{nowShowing?.steps}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', columnGap: 4 }}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={auditionMode}
                                    exclusive
                                    onChange={handleAuditionMode}
                                    aria-label="Audition Mode"
                                    style={{ height: 24 }}
                                >
                                    <ToggleButton value="assisted">Assisted</ToggleButton>
                                    <ToggleButton value="self">Self</ToggleButton>
                                </ToggleButtonGroup>
                                {sessionName === 'couple_s1' && (
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={couplePartner}
                                        exclusive
                                        onChange={handleCouplePartner}
                                        aria-label="Couple Mode"
                                        style={{ height: 24 }}
                                    >
                                        <ToggleButton value="m2f">Male Partner</ToggleButton>
                                        <ToggleButton value="f2f">Female Partner</ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            </div>
                        </div>
                        <div className="audition-main-wrap">
                            {auditionMode === 'self' ? (
                                <>
                                    <div className="aud-section panel-1 flex-7">
                                        <ReactFullpage
                                            keyboardScrolling
                                            scrollingSpeed={600}
                                            controlArrows={false}
                                            waterEffect={false}
                                            loopHorizontal={false}
                                            responsiveHeight={400}
                                            onSlideLeave={onSlideChange}
                                            onScrollOverflow={() => console.log('On Overflow')}
                                            render={({ state, fullpageApi }) => {
                                                api = fullpageApi;
                                                // fullpageApi?.moveSlideRight();
                                                // fullpageApi?.setKeyboardScrolling(true, 'left, right');
                                                return (
                                                    <ReactFullpage.Wrapper>
                                                        <div className="section" id="section0">
                                                            {nowShowing?.images?.map((image, idx) => {
                                                                const d = image?.split('/');
                                                                const [meta, imgSrc] = d;
                                                                const img = imgSrc || meta;
                                                                const folder = d.length === 2 ? meta : sessionName;
                                                                return (
                                                                    <div key={idx} className="slide" data-anchor={`slide${idx + 1}`}>
                                                                        <img className="img-poses onsite" src={`https://imm-audition.s3.ap-south-1.amazonaws.com/${folder}/${img}`} />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </ReactFullpage.Wrapper>
                                                );
                                            }}
                                        />
                                        <div className="wm-wrap">
                                            &nbsp;
                                        </div>
                                    </div>
                                    <div className="aud-section panel-2 flex-4 flex-column">
                                        <AuditionCapture previewDefault selfAuditionMode={selfAuditionMode} hideChat imgSrc={imgSrc} onCapture={handleOnCapture} webcamRef={webcamRef} onPoseComplete={handleMarkComplete} />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="aud-section panel-1 flex-2 panel-info flex-column">
                                        <AuditionInfo />
                                    </div>
                                    <div className="aud-section panel-1 flex-6">
                                        <ReactFullpage
                                            keyboardScrolling
                                            scrollingSpeed={600}
                                            waterEffect={false}
                                            loopHorizontal={false}
                                            controlArrows={false}
                                            responsiveHeight={400}
                                            onSlideLeave={onSlideChange}
                                            onScrollOverflow={() => console.log('On Overflow')}
                                            render={({ state, fullpageApi }) => {
                                                api = fullpageApi;
                                                // fullpageApi?.setKeyboardScrolling(true, 'left, right');
                                                return (
                                                    <ReactFullpage.Wrapper>
                                                        <div className="section" id="section0">
                                                            {nowShowing?.images?.map((image, idx) => {
                                                                const d = image?.split('/');
                                                                const [meta, imgSrc] = d;
                                                                const img = imgSrc || meta;
                                                                const folder = d.length === 2 ? meta : sessionName;
                                                                return (
                                                                    <div key={idx} className="slide" data-anchor={`slide${idx + 1}`}>
                                                                        <img className="img-poses onsite" src={`https://imm-audition.s3.ap-south-1.amazonaws.com/${folder}/${img}`} />
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </ReactFullpage.Wrapper>
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="aud-section panel-2 flex-2 flex-column p-4">
                                        <AuditionOverview
                                            auditionMap={auditionMap}
                                            activeStep={nowShowing?.progess - 1}
                                            isLastSlide={isLastSlide}
                                            onAuditionComplete={handleAuditionComplete}
                                            onPoseComplete={handleMarkComplete}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuditionInterview;
