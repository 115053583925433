import React from "react";
import Header from './Header';
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

const PrivateLayout = () => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isAuthenticated && !isLoading) {
        return (
            <>
                <div className="main-nav">
                    <Header />
                    <Outlet />
                </div>
            </>
        )
    }

    if (isLoading) {
        return 'Loading...'
    }

    return <Navigate to='/audition/login' />
}

export default PrivateLayout;