import { useEffect, useState } from 'react';
import { useFirebase } from '../providers/FirebaseProvider';
import { useMemo } from 'react';

const useMessages = (msgPath, type = 'chat', onlyLatest = false) => {
    const { listenToMessages } = useFirebase();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const unsubscribe = listenToMessages(msgPath, (data) => {
            setMessages(Object.values(data));
        });

        return () => {};
    }, []);

    const msgStream = useMemo(() => {
        const msgs = messages?.reduce((acc, cur) => {
            return {
                ...acc,
                [cur?.type]: [ ...acc?.[cur?.type], cur],
            }
        }, { chat: [], info: [], slideShow: [], action: [] });

        const msgsByType = msgs?.[type] ?? msgs;

        if (onlyLatest) {
            return msgsByType?.[msgsByType?.length - 1];
        }
        
        return msgsByType
    }, [messages, type, onlyLatest]);

    return msgStream;
};

export default useMessages;