import { useEffect, useState } from 'react';

let image;
function useImageOrientation() {
    const [orientation, setOrientation] = useState(null);

    useEffect(() => {
        const image = document.getElementById('aud-image');

        if (!image) {
            console.error("Image element with id 'aud-image' not found.");
            return;
        }

        const loadImage = () => {
            const width = image.naturalWidth;
            const height = image.naturalHeight;

            if (width > height) {
                setOrientation('landscape');
            } else if (width < height) {
                setOrientation('portrait');
            } else {
                setOrientation('portrait');
            }
        };

        if (image.complete) {
            loadImage();
        } else {
            image.addEventListener('load', loadImage);
        }

        // Cleanup
        return () => {
            image.removeEventListener('load', loadImage);
        };
    }, []);

    return orientation;
}

export default useImageOrientation;