import React from "react";
import Header from './Header';
import { Outlet } from "react-router-dom";

const Layout = () => {
    return (
        <>
            <div className="main-nav">
                <Header />
                <Outlet />
            </div>
        </>
    )
}

export default Layout;